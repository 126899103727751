<template>
  <div>
    <div class="input_container">
      <el-input :type="isShow ? 'password' : 'text'" @input="p_len" @focus="showmsg" @blur="getpass" v-model="form.password"
                placeholder="请输入新密码">
        <template slot="suffix">
          <img style="width: 15px;" @click="isShow = !isShow"
               :src="isShow ? require('../../../assets/img/homePage/EyeInvisible.png') : require('../../../assets/img/homePage/mimakejian.png')"/>
        </template>
      </el-input>
      <span v-bind:class="{ valid_password_length: valid_password_length , show_password_length: typed}"
            class="password_length">{{ password_length }}</span>
      <div class="lnu_container" v-if="msgs">
        <p>您的密码需满足以下格式：</p>
        <p>
          <i class="el-icon-success" v-bind:class="{ longer_valid: contains_longer }"></i>
          至少6位
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dialogSetPassword: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShow: true,
      form: {
        password: "",
      },
      password_length: 0,
      typed: false,
      msgs: false,
      valid_password_length: false,
      contains_longer: false,
      valid_password: false
    }
  },
  watch: {
    dialogSetPassword: {
      handler(v) {
        if (!v) {
          this.clearPass();
        }
      },
      immediate: true
    }
  },
  methods: {
    p_len: function () {
      this.password_length = this.form.password.length;
      this.valid_password_length = this.password_length >= 6;
      this.typed = this.password_length > 0;
      this.contains_longer = this.valid_password_length;
      this.valid_password = this.valid_password_length;
    },
    showmsg() {
      this.msgs = true;
    },
    getpass() {
      this.msgs = false;
      this.$emit('password', this.form.password, this.valid_password);
    },
    clearPass() {
      this.form.password = '';
      this.password_length = 0;
      this.valid_password_length = false;
      this.typed = false;
      this.contains_longer = false;
    }
  },
  mounted() {

  }
}
</script>
<style lang="scss" scoped>
input[type="password"]::-webkit-input-placeholder {
  color: rgba(71, 87, 98, 0.8);
}

input[type="password"] :-moz-input-placeholder {
  color: rgba(71, 87, 98, 0.8);
}

#app {
  height: auto;
  padding: 10px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#app h2 {
  font-size: 25px;
  color: #475762;
  text-align: center;
  margin-bottom: 20px;
}

.input_container {
  display: block;
  margin: 0 auto;
  height: auto;
  position: relative;
  border-radius: 4px;
  // overflow: hidden;
  // margin-bottom: 10px;
}

#app input[type="password"] {
  height: auto;
  border: 1px solid transparent;
  background: #EEEEEE;
  color: #475762;
  font-size: 15px;
  border-radius: 4px;
  padding: 12px 5px;
  overflow: hidden;
  outline: none;
  -webkit-transition: all .1s;
  transition: all .1s;
}

#app input[type="password"]:focus {
  border: 1px solid #2196F3;
}

.password_length {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 16.5px;
  right: 25px;
  text-align: center;
  line-height: 30px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: #FBD490;
  color: rgba(71, 87, 98, 0.8);
  border-radius: 4px;
  font-size: 13px;
  display: none;
  -webkit-transition: all .1s;
  transition: all .1s;
}

.valid_password_length {
  background: #00AD7C;
  color: rgba(255, 255, 255, 0.9);
}

.show_password_length {
  display: block;
}

.lnu_container {
  display: block;
  margin: 10px auto;
  height: auto;
  position: absolute;
  top: 32px;
  left: 13%;
  z-index: 10;
  width: 220px;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 10px;
  padding: 10px 0;
  // display: -webkit-box;
  // display: -ms-flexbox;
  // display: flex;
  // -webkit-box-pack: justify;
  //     -ms-flex-pack: justify;
  //         justify-content: space-between;
}

.lnu_container p {
  width: 100%;
  height: auto;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  border-radius: 2px;
}

.lnu_container i {
  color: rgba(71, 87, 98, 0.8);
  border-radius: 10px;
  background: -webkit-linear-gradient(left, #00AD7C 50%, #eee 50%);
  background: linear-gradient(to right, #00AD7C 50%, #eee 50%);
  background-size: 201% 100%;
  background-position: right;
  -webkit-transition: background .3s;
  transition: background .3s;
}

.lovercase_valid,
.number_valid,
.uppercase_valid,
.longer_valid {
  background-position: left !important;
  color: rgba(255, 255, 255, 0.9) !important;
}

.valid_password_container {
  display: block;
  margin: 10px auto;
  border-radius: 4px;
  position: relative;
  background: #00AD7C;
  width: 20px;
  height: 20px;
  visibility: hidden;
  opacity: 0;
}

.show_valid_password_container {
  visibility: visible;
  opacity: 1;
}

.tick {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 25;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.checked {
  -webkit-animation: draw 0.5s ease forwards;
  animation: draw 0.5s ease forwards;
}

@-webkit-keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

</style>
